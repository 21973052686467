import React from "react"

export default function Hero() {
  return (
    <div className="hero">
      <div className="blurb">
        <h1>
          Hi, my name is <strong>Ian McGonigle</strong>.
        </h1>
        <div className="hr-with-text">
          <hr />
          <span>I'm a</span>
          <hr />
        </div>
        <h1 className="subheader">Software Engineer</h1>
      </div>
    </div>
  )
}
