import React from "react"

import { Hero, Skills, Contact, Footer, Portfolio } from "./sections"

export default function Home() {
  return (
    <div>
      <Hero />
      <Skills />
      <Portfolio />
      <Contact />
      {/*
      <Footer />
      */}
    </div>
  )
}
