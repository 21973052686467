import React from "react"

import Section from "../../../components/section"

import ContactStyles from "./contact.module.css"

export default function Contact() {
  return (
    <Section bgColor="#7caad5" color="#2a3a3f" title="Get In Touch">
      <p className={ContactStyles.blurb}>
        Send me an email I would love to hear about what you're working on. I
        would be happy to help out in any way I can.
      </p>
      <a className={ContactStyles.button} href="mailto:ian@ianmcgonigle.com">
        Email Me
      </a>
    </Section>
  )
}
