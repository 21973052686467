import React from "react"

import Contact from "./contact"
import Footer from "./footer"
import Hero from "./hero"
import Portfolio from "./portfolio"
import Skills from "./skills"

function DoNothing() {
  return <div></div>
}

export { DoNothing as default, Contact, Footer, Hero, Portfolio, Skills }
