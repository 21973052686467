import React from "react"

import projectStyles from "./project.module.css"

export default function Project({
  title,
  description,
  summary,
  repoLink,
  imageURL,
  reverse,
}) {
  return (
    <div
      className={
        reverse ? projectStyles.containerReverse : projectStyles.container
      }
    >
      <div className={projectStyles.info}>
        <h1 className={projectStyles.title}>{title}</h1>
        <p className={projectStyles.summary}>{summary}</p>
        <p className={projectStyles.description}>{description}</p>
        <div className={projectStyles.buttonContainer}>
          <a
            className={projectStyles.button}
            href={repoLink}
            target="_blank"
            rel="noreferrer"
          >
            View Code
          </a>
        </div>
      </div>
      <div className={projectStyles.imageContainer}>
        <img className={projectStyles.image} src={imageURL}></img>
      </div>
    </div>
  )
}
