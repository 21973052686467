import React from "react"

import Section from "../../../components/section"
import Project from "../../../components/project"

import ComputerSVG from "../../../images/flat-icon-computer.svg"

export default function Portfolio() {
  return (
    <Section
      bgColor="#eee"
      title="My Work"
      statement={
        <div>
          You can see more of my work on my{" "}
          <a
            style={{ textDecoration: "none", color: "#03a9f4" }}
            href="https://www.github.com/imcgonigle"
            target="_blank"
            rel="noreferrer"
          >
            GitHub
          </a>
        </div>
      }
    >
      <Project
        title="Heart 2 Heart"
        summary="Mobile application developed with a 3rd grade class."
        description="Heart 2 Heart is a mobile application that was designed and developed with the help of Ms. Easter's & Mrs. Johnson's 3rd grade class. Heart 2 Heart helps you improve your mood by providing you with a variety of resourcs that will cheer you up."
        repoLink="https://github.com/imcgonigle/heart-2-heart-ionic"
        imageURL={ComputerSVG}
      ></Project>
      <hr
        style={{
          width: "60%",
          margin: "3rem auto",
          borderTop: "1px solid #ccc",
        }}
      ></hr>
      <Project
        reverse
        title="Inventory Up"
        summary="Web application for managing inventories."
        description="Inventory Up allows you to easily manage inventories of any size from anywhere. Inventory Up runs completely in the browser so you can use it on your phone, laptop, or desktop machine."
        repoLink="https://github.com/imcgonigle/Inventory-Up"
        imageURL={ComputerSVG}
      ></Project>
    </Section>
  )
}
